import { useParams } from 'react-router-dom';
import AppNav from '../../components/AppNav/AppNav';
import LogDetail from '../../components/LogDetail';
import EmailLogEntryTypePill from '../../components/EmailLogEntryTypePill';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import ApiClient from '../../utils/ApiClient';
import { useMsal } from '@azure/msal-react';
import { API_BASE_URI } from '../../appConfig';
import StateToMessageMapping from '../../StateToMessageMapping';

function FormInstanceLogDetailPage() {
  const [ fetchingFormInstanceLog, setFetchingFormInstanceLog ] = useState(true);
  const [ formInstanceLog, setFormInstanceLog ] = useState();
  const { hash } = useParams();

  const { accounts, instance } = useMsal();
  const isManager = instance.getActiveAccount().idTokenClaims.roles.includes('Forms.Edit');

  useEffect(() => {
    const fetchData = async () => {
      setFetchingFormInstanceLog(true);
      try {
        const response = await ApiClient.get(`/admin/logs/formInstanceLog/${hash}`, {
          headers: {
            'Authorization': `Bearer ${(await instance.acquireTokenSilent({account: instance.getActiveAccount(), scopes: ["api://d5d22785-173e-47f9-b48c-4909fc3b9098/Api.Access"]})).accessToken}`
          }
        });

        setFormInstanceLog(response.data);
      } catch (e) {

      } finally {
        setFetchingFormInstanceLog(false);
      }
    };
    fetchData();
  }, []);

  const onDownloadClick = async () => {
    const response = await ApiClient.post(`/admin/formInstances/${hash}/downloadLink`, {}, {
      headers: {
        'Authorization': `Bearer ${(await instance.acquireTokenSilent({account: instance.getActiveAccount(), scopes: ["api://d5d22785-173e-47f9-b48c-4909fc3b9098/Api.Access"]})).accessToken}`
      }
    });

    window.open(`${API_BASE_URI}/admin/formInstances/${hash}/download?t=${response.data.downloadToken}`)
  }

  if (!hash) {
    return (
      <div>
        <AppNav />
        <div className="container-fluid">
          <h1>Formular Instanz Detail Log</h1>
          Kein Log-Entry übergeben
        </div>
      </div>
    );
  }

  if (fetchingFormInstanceLog) {
    return (
      <div>
        <AppNav />
        <div className="container-fluid">
          <h1>Formular Instanz Detail Log</h1>
          Lade Daten
        </div>
      </div>
    );
  }

  return (
    <div>
      <AppNav />
      <div className="container-fluid">
        <h1>Formular Instanz Detail Log</h1>
        <h5 className="text-muted text-uppercase">INSTANZ: {formInstanceLog.hash}</h5>
        <div className="text-end">
          <button className="btn btn-outline-primary" onClick={onDownloadClick} disabled={!formInstanceLog.downloadAvailable}>Formular Download</button>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-black">Formular Details</h5>
                <LogDetail title="FormCode" value={formInstanceLog.form?.formCode} />
                <LogDetail title="Status" value={
                  formInstanceLog.state !== 'STATE_FROM_SAP' ?
                    <EmailLogEntryTypePill logType={'PENDING'}>OFFEN</EmailLogEntryTypePill> :
                    <EmailLogEntryTypePill logType={formInstanceLog.state_type}>SAP: OK</EmailLogEntryTypePill>
                } />
                <LogDetail title="Formular Typ" value={formInstanceLog.form?.formType} />
                <LogDetail title="Innenauftrag" value={formInstanceLog.form?.orderNumber} />
                <LogDetail title="Buchungskreis" value={formInstanceLog.form?.bukrs} />
                <LogDetail title="Produktion" value={formInstanceLog.form?.production} />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title text-black">Verlauf</h5>
                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th scope="col">Zeitpunkt</th>
                    <th scope="col">Nachricht</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    formInstanceLog.actionLog.map((logItem) => (
                      <tr>
                        <td>
                          { moment(logItem.timestamp).format('DD.MM.YYYY HH:mm:ss') }
                        </td>
                        <td>
                          <div>{ logItem.message || StateToMessageMapping(logItem) || <>&nbsp;</> }</div>
                          <div className="text-muted"><small>Status: { logItem.state }</small></div>
                        </td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormInstanceLogDetailPage;
