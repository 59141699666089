import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import HomePage from './pages/HomePage/HomePage';
import FormPage from './pages/FormPage/FormPage';
import FormDetailPage from './pages/FormDetailPage/FormDetailPage';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { CustomNavigationClient } from './utils/NavigationClient';
import LoginPage from './pages/LoginPage/LoginPage';
import PDFViewerPage from './pages/PDFViewerPage/PDFViewerPage';
import PrivacyTermsPage from './pages/PrivacyTermsPage/PrivacyTermsPage';
import EmailLogPage from './pages/EmailLogPage/EmailLogPage';
import EmailLogDetailPage from './pages/EmailLogPage/EmailLogDetailPage';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import FormInstanceLogPage from './pages/FormInstanceLogPage/FormInstanceLogPage';
import FormInstanceLogDetailPage from './pages/FormInstanceLogPage/FormInstanceLogDetailPage';

// Admin Pages are lazy loaded to decrease public bundle size for attendants
const FormsListPage = React.lazy(() => import('./pages/FormsListPage/FormsListPage'));
const NewFormPage = React.lazy(() => import('./pages/NewFormPage/NewFormPage'));

function App() {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  instance.setNavigationClient(navigationClient);

  return (
    <div className="App">
      <Routes>
        <Route index element={<HomePage />} />
        <Route path=":formCode" element={<FormPage />} />
        <Route path="/privacy" element={<PrivacyTermsPage />} />
        <Route path="/support/contact-us" element={<ContactUsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="panel/*" element={
          <>
            <UnauthenticatedTemplate>
              <Navigate replace to="/login" state={{ reason: 'NOT_AUTHENTICATED' }} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <React.Suspense fallback={<div>Loading Admin Panel</div>}>
                <Routes>
                  <Route path="/" element={<Navigate replace to="/panel/forms" />} />
                  <Route path="forms" element={<FormsListPage />} />
                  <Route path="forms/new" element={<NewFormPage />} />
                  <Route path="forms/:formCode/qrPDF" element={<PDFViewerPage />} />
                  <Route path="forms/:formCode" element={<FormDetailPage />} />
                  <Route path="logs/email" element={<EmailLogPage />} />
                  <Route path="logs/email/:logId" element={<EmailLogDetailPage />} />
                  <Route path="logs/formInstances" element={<FormInstanceLogPage />} />
                  <Route path="logs/formInstances/:hash" element={<FormInstanceLogDetailPage />} />
                </Routes>
              </React.Suspense>
            </AuthenticatedTemplate>
          </>
        } />
      </Routes>
    </div>
  );
}

export default App;
