import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import ApiClient from '../../utils/ApiClient';
import moment from 'moment-timezone';
import AppNav from '../../components/AppNav/AppNav';
import EmailLogEntryTypePill from '../../components/EmailLogEntryTypePill';
import { Link } from 'react-router-dom';
import { API_BASE_URI } from '../../appConfig';

function FormInstanceLogPage() {
  const { accounts, instance } = useMsal();
  const isManager = instance.getActiveAccount().idTokenClaims.roles.includes('Forms.Edit');

  const [ logEntries, setLogEntries ] = useState([]);
  const [ fetchingLogEntries, setFetchingLogEntries ] = useState([]);

  useEffect(() => {
    const fetchLogEntries = async () => {
      setFetchingLogEntries(true);
      try {
        const response = await ApiClient.get('/admin/logs/formInstanceLog', {
          headers: {
            'Authorization': `Bearer ${(await instance.acquireTokenSilent({account: instance.getActiveAccount(), scopes: ["api://d5d22785-173e-47f9-b48c-4909fc3b9098/Api.Access"]})).accessToken}`
          }
        });

        setLogEntries(response.data);
      } catch (e) {

      } finally {
        setFetchingLogEntries(false);
      }
    };

    fetchLogEntries();
  }, []);

  return (
    <div>
      <AppNav />
      <div className="container">
        <h1>Formular Log</h1>

        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">FormCode</th>
            <th scope="col">Hash</th>
            <th scope="col">Status</th>
            <th scope="col">Details</th>
          </tr>
          </thead>
          <tbody>
          {
            fetchingLogEntries ? (
              <tr>
                <th scope="row" colSpan="5">
                  Lade Daten
                </th>
              </tr>
            ) : logEntries.length === 0 ? (
              <tr>
                <th scope="row" colSpan="5">
                  Keine Logs gefunden
                </th>
              </tr>
            ) : logEntries.map((logEntry) => (
              <tr key={logEntry.hash}>
                <td>
                  { logEntry.formCode }
                </td>
                <td>
                  { logEntry.hash }
                </td>
                <td>
                  { logEntry.state }
                </td>
                <td>
                  <Link to={`/panel/logs/formInstances/${logEntry.hash}`}>Details</Link>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FormInstanceLogPage;
