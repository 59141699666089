import red7Logo from '../../assets/red7-logo.png';
import { Link, NavLink } from 'react-router-dom';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Container, Nav, Navbar } from 'react-bootstrap';

function AppNav() {
  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {
        process.env.REACT_APP_BUILD_MODE !== 'production' ? (
        <div style={{lineHeight: '2rem'}} className="text-center bg-info text-white">
          Build: { process.env.REACT_APP_BUILD_MODE }
        </div>
        ) : null
      }
      <Navbar expand="lg" className="navbar-dark bg-black">
        <Container fluid>
          <Link to={isAuthenticated ? '/panel' : '/'} className="navbar-brand">
            <img src={red7Logo} alt="RedSeven Entertainment Logo" height="60" />
          </Link>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <AuthenticatedTemplate>
            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="navbar-nav me-auto mb-2 mb-lg-0">
                <NavLink to="/panel/forms" end className="nav-link">Formulare</NavLink>
                <NavLink to="/panel/forms/new" className="nav-link">Neues Formular</NavLink>
                <AuthenticatedTemplate>
                  <li className="nav-item">
                    <NavLink to="/panel/logs/email" className="nav-link">E-Mail Log</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/panel/logs/formInstances" className="nav-link">Formular Log</NavLink>
                  </li>
                </AuthenticatedTemplate>
              </Nav>
              <AuthenticatedTemplate>
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="navbar-text text-end">
                    <small>
                      { accounts.length > 0 ? accounts[0].name : null }<br/>
                      <button className="btn btn-sm btn-link" onClick={() => instance.logoutRedirect({
                        postLogoutRedirectUri: '/'
                      })}>Abmelden</button>
                    </small>
                  </li>
                </ul>
              </AuthenticatedTemplate>
            </Navbar.Collapse>
          </AuthenticatedTemplate>
        </Container>
      </Navbar>
    </>
  );
}

export default AppNav;
