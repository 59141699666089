const StateToMessageMapping = (logItem) => {
  switch (logItem.state) {
    case 'FORM_INSTANCE_FOLDER_CREATED':
      return 'Ordner für Formularinstanz erstellt.';
    case 'FORM_INSTANCE_ORIGINAL_DATA_FOLDER_CREATED':
      return 'Ordner für unkonvertierte Daten erstellt.'
    case 'FORM_INSTANCE_PDF_CREATED':
      return'Formular PDF erstellt.';
    case 'FORM_INSTANCE_ATTACHMENTS_CONVERTED':
      return 'Anhänge konvertiert und gebündelt.';
    case 'FORM_INSTANCE_ZIP_CREATED':
      return 'Formular ZIP File erstellt.';
    case 'FORM_INSTANCE_SENT_TO_SAP':
      return 'Formular an SAP gesendet.';
    case 'FORM_INSTANCE_ATTENDEE_CONFIRMATION_SENT':
      return 'Bestätigungsmail an Teilnehmer:in gesendet.';
    case 'FORM_INSTANCE_FORM_INVALIDATED':
      return 'Formularcode invalidiert.';
    case 'FORM_INSTANCE_SENT_TO_ADMIN_FOR_MANUAL_PROCESSING':
      return 'Formulardaten zur manuellen Weiterverarbeitung geschickt';
    case 'STATE_FROM_SAP':
      return `Status von SAP: ${logItem.type}`;
  }
};
export default StateToMessageMapping;
